<template>
    <div class="vx-row">
		<div class="vx-col md:w-1/1 w-full">
			<vx-card>
                <span>
                    <h4 class="mb-4" v-if="this.$route.params.id">Edit Role Menu</h4>
                    <h4 class="mb-4" v-else>Add Role Menu</h4>
                </span>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Role</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <vs-input class="w-full" v-model="role" readonly />
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Menu</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full" :data="data">
                        <ul :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                            <li class="mb-1">
                                <vs-checkbox v-if="data[indextr].parent_id == 0" :checked="data[indextr].selected" v-on:click="addChecked(data[indextr].module_id, null)">{{ data[indextr].display_name }}</vs-checkbox>
                                <vs-checkbox v-else style="margin-left: 5%" :checked="data[indextr].selected" v-on:click="addChecked(data[indextr].module_id, data[indextr].parent_id)">{{ data[indextr].display_name }}</vs-checkbox>
                                <!-- <vs-checkbox :checked="data[indextr].selected" v-on:click="addChecked(data[indextr].id)">{{ data[indextr].display_name }}</vs-checkbox> -->
                            </li>
                        </ul>
					</div>
				</div>
				<div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-4/5 w-full ml-auto">
                        <vs-button v-if="this.$route.params.id" v-on:click="handleSubmit" class="mr-3 mb-2">Update</vs-button>
						<vs-button v-else v-on:click="handleSubmit" class="mr-3 mb-2">Create</vs-button>
					</div>
				</div>
			</vx-card>
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
export default {
    components: {
        vSelect
    },
    beforeMount(){
        this.getMenu()
    },
    created() {
        // this.getMenu();
    },
    mounted(){
        // this.getData();
        
    },
    data() {
        return {
            data: [],
            selected: [],
            role: "",
        };
    },
    methods: {
        addChecked(val, valParent) {
            if (this.selected.includes(val)) {
                this.selected.splice(this.selected.indexOf(val), 1);
                var _this = this;
                this.data.forEach(function(element){
                    if(element.parent_id == val){
                        element["selected"] = false;
                        if(element.parent_id != 0){
                            element["selected"] = false;
                            _this.selected.splice(_this.selected.indexOf(element.module_id), 1);
                        }
                    }

                    if(element.module_id == val){
                        element["selected"] = false
                    }

                })
            } else {
                this.selected.push(val);
                var _thiss = this;
                this.data.forEach(function(element){
                    if(element.parent_id == val){
                        element["selected"] = true;
                        if(_thiss.selected.includes(element.module_id)){
                            console.log(element.module_id)
                        } else {
                            _thiss.selected.push(element.module_id);
                        }
                    }

                    if(element.module_id == val){
                        element["selected"] = true
                    }
                    
                    if(element.parent_id == 0 && element.module_id == valParent){
                        element["selected"] = true;
                        if(_thiss.selected.includes(element.module_id)){
                            console.log(element.module_id)
                        } else {
                            _thiss.selected.push(element.module_id);
                        }
                    }
                })
            }

            var _th = this;
            this.data.forEach(function(element){
                var status = []
                if(element.module_id == valParent){
                    // console.log("masuk")
                    
                    _th.data.forEach(function(element1){
                        if(element1.parent_id == valParent){
                            status.push(element1.selected)
                            // console.log(element1.selected+" "+element1.display_name)
                        }
                    })
                    // console.log("-------------------------------------------------------")
                    if(status.includes(true)){
                        element["selected"] = true
                        // console.log("masih checked")
                    } else {
                        element["selected"] = false
                        _th.selected.splice(_th.selected.indexOf(element.module_id), 1);
                        // console.log("harusnya un-checked")
                    }
                }
            })


            console.log(this.selected)


        },
        getMenu(){
            this.$vs.loading();
            this.$http
            .get("/api/sfa/v1/setting/menu", {
                params: {
                    source: "SFA"
                }
            }).then(resp => {
                if (resp.code == 200) {

                    var x = resp.data.records,
                    result = x.reduce(function (r, a) {
                        r[a.parent_id] = r[a.parent_id] || [];
                        r[a.parent_id].push(a);
                        return r;
                    }, Object.create(null));
                    var y = [];
                    var z = [];
                    var a = [];

                    Object.keys(result).forEach(function(key) {

                        if(key == 0){
                            result[key].forEach(function(element){
                                z.push({module_id: element.id, display_name: element.display_name})
                            });
                        }

                        if(key != 0){
                            result[key].forEach(function(element){
                                y.push({parent_id: element.parent_id, display_name: element.display_name, module_id: element.id})
                            });
                        }
                    });

                    z.forEach(function(elementZ) {
                        a.push({parent_id: 0, module_id: elementZ.module_id, display_name: elementZ.display_name, selected: false})
                        y.forEach(function(elementY) {
                            if(elementY.parent_id == elementZ.module_id){
                                a.push({parent_id: elementY.parent_id, display_name: elementY.display_name, module_id: elementY.module_id, selected: false})
                            }
                        });
                    });
                    this.data = a;
                }
                this.getData()
            });
        },
        getData(){
            this.$vs.loading();
            this.$http
            .get("/api/sfa/v1/setting/role-menu/" + this.$route.params.id).then(resp => {
                if (resp.code == 200) {
                    this.$vs.loading.close();
                    this.role = resp.data.display_name;
                    var x = resp.data.module
                    var _this = this;
                    this.data.forEach(function(element){
                        x.forEach(function(elementX){
                            if(element.module_id == elementX.module_id){
                                element["selected"] = true;
                                _this.selected.push(elementX.module_id)
                            }
                        })
                    })
                }
            });
        },
        handleSubmit() {
            this.$validator.validateAll().then(result => {
                if (result) {
                    console.log(this.paramData())
                    if(this.$route.params.id){
                        this.putData();
                    }
                }
            });
        },
        paramData() {
            return {
                menu: this.selected,
            };
        },
        putData() {
            this.$vs.loading();
            this.$http
            .put("/api/sfa/v1/setting/role-menu/" + this.$route.params.id, this.paramData())
            .then(resp => {
                if (resp.code == 200) {
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: "Role Menu Updated",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
                this.$vs.loading.close();
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
    },
};
</script>